import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Icon, Link, List, Section } from "@quarkly/widgets";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
const defaultProps = {
	"color": "--light",
	"padding": "100px 0",
	"sm-padding": "40px 0",
	"position": "relative",
	"background": "linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://qmist-juns.com/img/3.jpg)",
	"quarkly-title": "Form-1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"padding": "8px 16px 8px 16px",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"font": "--headline1",
			"color": "--light",
			"text-align": "left",
			"sm-font": "normal 700 62px/1.2 \"Source Sans Pro\", sans-serif",
			"children": <>
				Контакты{"  "}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--lead",
			"color": "--light",
			"text-align": "left",
			"children": "Свяжитесь с качественным автомобильным обслуживанием и советом:"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "50%",
			"md-width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"sm-padding": "64px 0 0 0",
			"margin": "32px 0 0 0",
			"max-width": "560px",
			"position": "relative",
			"padding": "0 0 0 64px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"size": "48px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdLocationOn,
			"position": "absolute"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "--base",
			"children": "Посетите нас"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": "Harmu Rd, Opposite Apollo Pharmacy, Kumhartoli, Ranchi, Jharkhand 834001"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"padding": "0 0 0 64px",
			"sm-padding": "64px 0 0 0",
			"margin": "64px 0 0 0",
			"max-width": "360px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdEmail,
			"position": "absolute",
			"size": "48px"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"as": "h4",
			"margin": "6px 0",
			"children": "Свяжитесь с нами по электронной почте"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": <Link href="mailto:admin@qmist-juns.com" text-decoration="none" hover-text-decoration="underline" color="--light">
				admin@qmist-juns.com
			</Link>
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"margin": "64px 0 0 0",
			"max-width": "360px",
			"position": "relative"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"left": "0",
			"category": "md",
			"icon": MdPhone,
			"position": "absolute",
			"size": "48px",
			"top": "0"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"as": "h4",
			"margin": "6px 0",
			"children": "Call us"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": "+91 91671 63878"
		}
	},
	"list": {
		"kind": "List",
		"props": {
			"margin": "20px 0px 0px 0px",
			"padding": "12px 0px 12px 0px",
			"list-style-type": "none",
			"as": "ul",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/index",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Дом"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "/services",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "Услуги"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "/faq",
			"color": "white",
			"padding": "6px 12px 6px 12px",
			"text-decoration-line": "initial",
			"display": "flex",
			"font": "20px/30px sans-serif",
			"hover-color": "--lightD2",
			"transition": "background-color 0.1s ease 0s",
			"children": "FAQ"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text")} />
					<Text {...override("text1")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Box {...override("box5")}>
						<Icon {...override("icon")} />
						<Text {...override("text2")} />
						<Text {...override("text3")} />
					</Box>
					<Box {...override("box6")}>
						<Icon {...override("icon1")} />
						<Text {...override("text4")} />
						<Text {...override("text5")} />
					</Box>
					<Box {...override("box7")}>
						<Icon {...override("icon2")} />
						<Text {...override("text6")} />
						<Text {...override("text7")} />
					</Box>
				</Box>
			</Box>
		</Box>
		<List {...override("list")}>
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
		</List>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;